import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Temp } from './temp';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
initializeIcons();

function App() {
  return (<Temp></Temp>);
}

export default App;
