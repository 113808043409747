import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    DatePicker,
    IDatePickerStrings,
    defaultDatePickerStrings,
    addMonths,
    addYears,
    IDatePickerStyles,
    Stack,
    Label,
    DayOfWeek,
    addDays,
    IconButton,
    Icon,
    mergeStyles,
    mergeStyleSets,
    FontIcon,
} from '@fluentui/react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useConst } from "@fluentui/react-hooks";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const Temp = () => {

    // Datepicker
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const minDate = useConst(new Date("2020-12-13"));
    const maxDate = yesterday;

    const [selectedDate, setSelectedDate] = useState(yesterday);

    const goPrevious = useCallback(() => {
        setSelectedDate(prevSelectedDate => (prevSelectedDate ? addDays(prevSelectedDate, -1) : undefined));
      }, []);
    
      const goNext = useCallback(() => {
        setSelectedDate(prevSelectedDate => (prevSelectedDate ? addDays(prevSelectedDate, 1) : undefined));
      }, []);

    const [data, setData] = useState(null);
    const dateString = useRef("");
    const totalPower = useRef(0);
    const sumIn = useRef(0);
    const sumOut = useRef(0);
    const tempInRef = useRef([]);
    const tempOutRef = useRef([]);

    useEffect(() => {
        getDay();
    }, [selectedDate]);

    const tooltipFooter = (tooltipItems) => {
        let tIn = 0;
        let tOut = 0;

        tooltipItems.forEach(function (tooltipItem) {
            const index = tooltipItem.dataIndex;
            tIn = tempInRef.current[index];
            tOut = tempOutRef.current[index];
        });

        return "Inside: " + tIn + "\xB0\nOutside: " + tOut + "\xB0";
    }

    const options = {   
        maintainAaspectRatiospectRatio: false,     
        locale: 'sv-SE',
        scales: {
            y: {
                ticks: {
                    callback: function (label) {
                        return `${this.getLabelForValue(label)} W`
                    }
                }
            }
        },
        responsive: true,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            tooltip: {
                position: 'bottom',
                callbacks: {
                    title: (tooltipItems) => tooltipItems[0].label + ":00" /* + ' ' +  data.datasets[tooltipItems[0].datasetIndex].label*/,
                    label: tooltipItems => tooltipItems.formattedValue + " W",
                    footer: tooltipFooter,
                }
            },
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text:
                    [
                        dateString.current + " (" + (totalPower.current / 1000) + " kWh)",
                        "Avg inside: " + (sumIn.current / 24).toFixed(1) + "\xB0",
                        "Avg outside: " + (sumOut.current / 24).toFixed(1) + "\xB0"
                    ]
            },
        },
    };

    const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    const labels = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

    const setChartData = (json) => {
        const heatData = [];
        const coolData = [];
        const tempIn = [];
        const tempOut = [];

        totalPower.current = 0;
        sumIn.current = 0;
        sumOut.current = 0;

        for (const label of hours) {
            for (const item of json) {
                if (item.hour === label) {
                    heatData.push(item.heat * 100);
                    coolData.push(item.cool * 100);
                    tempIn.push(item.tempIn);
                    tempOut.push(item.tempOut);
                    totalPower.current += (item.heat * 100) + (item.cool * 100);
                    sumIn.current += (item.tempIn);
                    sumOut.current += (item.tempOut);
                    break;
                }
            }
        }

        // don't display anything if there's no power data
        if (totalPower.current === 0) {
            sumIn.current = 0;
            sumOut.current = 0;
        }      

        tempInRef.current = tempIn;
        tempOutRef.current = tempOut;

        setData({
            labels: labels,
            datasets: [
                {
                    hidden: heatData.reduce((total, num) => total + num, 0) <= 0,
                    label: 'Heat',
                    data: heatData,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    hidden: coolData.reduce((total, num) => total + num, 0) <= 0,
                    label: 'Cool',
                    data: coolData,
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                }
            ],
        });
    };

    const getDay = async () => {        
        let date = selectedDate;

        var d = new Date(date);
        dateString.current = [
            d.getFullYear(),
            ('0' + (d.getMonth() + 1)).slice(-2),
            ('0' + d.getDate()).slice(-2)
        ].join('-');

        const year = date.getFullYear();
        const day = (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;       
        
        const response = await fetch(`${process.env.REACT_APP_API_URL}GetByDay?year=${year}&day=${day}`)
            .catch((error) => {
                console.log(error);
            })

        if (response) {
            const json = await response.json();
            setChartData(json);
        }
    }

    const datePickerStyles = { root: { maxWidth: 300, minWidth: 120 } };
    const iconClass = mergeStyles({
        fontSize: 20,
        height: 20,
        width: 20,
        marginLeft: "10px",
        //marginRight: "0px"
    });

    const classNames = mergeStyleSets({
        deepSkyBlue: [{ color: 'deepskyblue' }, iconClass],
        greenYellow: [{ color: 'greenyellow' }, iconClass],
        orange: [{ color: 'orange' }, iconClass],
        yellow: [{ color: 'yellow' }, iconClass],
        salmon: [{ color: 'salmon' }, iconClass],
        heat: [{ color: 'rgba(255, 99, 132, 0.5)' }, iconClass],
        
    });

    return (
        <>
            {data &&
                //<Stack style={{width: "50%", height: "50%"}}> 

                <Stack>                    
                    <Stack horizontal style={{ marginTop: 15 }} 
                            horizontalAlign="space-between"
                    >                        
                        <Stack id="statIcons" horizontal>
                            <Stack horizontal tokens={{ childrenGap: "10px" }} verticalAlign="center">
                                <FontIcon aria-label="" 
                                iconName={(sumOut.current / 24) < -15 ? "Freezing" : "Frigid"}
                                //className={(sumOut.current / 24) < -15 ? classNames.deepSkyBlue : classNames.orange} 
                                className={classNames.deepSkyBlue} 
                                />
                                <Stack>
                                    <Label>Out: {"X\u0305 " + (sumOut.current / 24).toFixed(1) + "\xB0"}</Label>
                                    {/* <Label>{(sumOut.current / 24).toFixed(1) + "\xB0"}</Label> */}
                                </Stack>

                                <FontIcon aria-label="" iconName="Frigid" className={classNames.deepSkyBlue} />
                                <Stack>
                                    <Label>In: {"X\u0305 " + (sumIn.current / 24).toFixed(1) + "\xB0"}</Label>
                                    {/* <Label>{(sumIn.current / 24).toFixed(1) + "\xB0"}</Label> */}
                                </Stack>

                                <FontIcon aria-label="" iconName="LightningBolt" className={classNames.deepSkyBlue} />
                                <Stack>
                                    <Label>{"\u2211 " + (totalPower.current / 1000) + " kWh"}</Label>
                                    {/* <Label>{(totalPower.current / 1000) + " kWh"}</Label> */}
                                </Stack>

                            </Stack>
                        </Stack>

                        <Stack id="calendar" horizontal 
                            style={{ marginLeft: 0 }}
                        >
                            <IconButton
                                disabled={selectedDate < minDate }
                                iconProps={{ iconName: "NavigateBack" }}
                                onClick={goPrevious}
                            />
                            <DatePicker
                                borderless
                                //underlined={true}
                                //textField={{iconProps: {iconName: null}}}
                                firstDayOfWeek={DayOfWeek.Monday}
                                minDate={minDate}
                                maxDate={maxDate}
                                formatDate={(date) => {
                                    return [
                                        date.getFullYear(),
                                        ('0' + (date.getMonth() + 1)).slice(-2),
                                        ('0' + date.getDate()).slice(-2)
                                    ].join('-');
                                }}
                                styles={datePickerStyles}
                                value={selectedDate}
                                onSelectDate={newDate => setSelectedDate(newDate)}
                            />
                            <IconButton
                            disabled={addDays(selectedDate, 1) > maxDate}
                                iconProps={{ iconName: "NavigateForward" }}
                                onClick={goNext}
                            />
                        </Stack>
                    </Stack>

                    {/* <Stack horizontal>
                            <Label style={{ marginLeft: "10px" }}>{"| Total: " + (totalPower.current / 1000) + " kWh"}</Label>
                            <Label style={{ marginLeft: "10px" }}>{"| Avg In: " + (sumIn.current / 24).toFixed(1) + "\xB0"}</Label>
                            <Label style={{ marginLeft: "10px" }}>{"| Avg Out: " + (sumOut.current / 24).toFixed(1) + "\xB0 |"}</Label>
                        </Stack> */}

                    <Bar
                        //width={100}
                        height={"140%"}
                        options={options}
                        data={data} />
                </Stack>

            }
        </>
    );

}

// Create a custom tooltip positioner to put at the bottom of the chart area
Tooltip.positioners.bottom = function (items) {
    const pos = Tooltip.positioners.average(items);

    // Happens when nothing is found
    if (pos === false) {
        return false;
    }

    const chart = this.chart;

    return {
        x: pos.x,
        y: chart.chartArea.bottom,
        xAlign: 'center',
        yAlign: 'bottom',
    };
}




